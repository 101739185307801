import { useMemo, useState, useEffect } from 'react'
import { Button, Grid, Tooltip } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { DialogEnum } from 'components/common/Dialog'
import DialogContainer from 'components/common/Dialog/DialogContainer'
import { useDispatch, useSelector } from 'react-redux'
import { isShipFulfillmentForSeller } from 'services/functionalResponsibilities'
import { openDialog } from 'store/dialog/actionCreator'
import { currentSeller, getSellerContacts } from 'store/selectors'
import { editSellerDistributionCenter } from 'store/seller/actionCreators'
import { SmsDistributionCenter } from 'types/Seller'
import { Role } from 'types/SellerUser'
import EnterpriseIcon, { InfoIcon } from '@enterprise-ui/icons'

export type OptionType = {
  id: string
  label: string
  value: string
}

type ComponentProps = {
  distributionCenter: SmsDistributionCenter
  isOpen: boolean
  isSellersPending: boolean
}

const EditDCContact = ({
  distributionCenter,
  isOpen,
  isSellersPending,
}: ComponentProps) => {
  const seller = useSelector(currentSeller)
  const sellers = useSelector(getSellerContacts)

  const [sellerContact, setSellerContact] = useState<OptionType>({
    id: '',
    label: '',
    value: '',
  })

  const shipFulfillSellers: OptionType[] = useMemo(
    () => isShipFulfillmentForSeller(seller?.id ?? '', sellers ?? []),
    [seller?.id, sellers],
  )

  useEffect(() => {
    const dcContact = shipFulfillSellers.find(
      (user) => user.id === distributionCenter.seller_user_id,
    )
    if (dcContact) setSellerContact(dcContact)
  }, [shipFulfillSellers, distributionCenter.seller_user_id])

  const dispatch = useDispatch()

  const handleOpenDialog = () => {
    const componentProps = {
      sellerVmmData: {
        seller_id: seller?.id,
        functional_responsibilities: [13],
      },
      sellerEntitlement: {
        role: Role.NONE,
      },
    }

    dispatch(
      openDialog({
        dialogEnum: DialogEnum.CREATE_SELLER_CONTACT,
        componentProps,
      }),
    )
  }

  const handleSubmit = () => {
    if (sellerContact) {
      const updatedDistributionCenter = {
        ...distributionCenter,
        seller_user_id: sellerContact.value,
      }
      dispatch(editSellerDistributionCenter(updatedDistributionCenter))
    }
  }

  const AddUser = () => {
    return (
      <Button
        type="ghost"
        className="hc-txt-uppercase"
        onClick={handleOpenDialog}
      >
        Add User
      </Button>
    )
  }

  return (
    <DialogContainer
      isOpen={isOpen}
      disableScroll
      title="Edit Distribution Center Contact"
      isPending={isSellersPending}
      isSubmitDisabled={!sellerContact}
      onSubmit={handleSubmit}
    >
      <Grid.Container direction="column">
        <Grid.Item className="hc-pt-expanded">
          <Autocomplete
            showSearchIcon
            id="seller_user_id"
            options={shipFulfillSellers}
            value={sellerContact}
            onUpdate={(_id, value) => {
              setSellerContact(value)
            }}
          ></Autocomplete>
        </Grid.Item>
        <Grid.Container justify="flex-end" spacing="dense">
          <Grid.Item>
            <AddUser />
          </Grid.Item>
          <Grid.Item className="hc-pt-sm">
            <Tooltip
              content="Adding a user will add a user to the Users and Contacts of this partner."
              location="top-left"
              className="hc-pr-sm"
            >
              <EnterpriseIcon
                icon={InfoIcon}
                style={{ color: '#3d70d6' }}
                size="sm"
              />
            </Tooltip>
          </Grid.Item>
        </Grid.Container>
      </Grid.Container>
    </DialogContainer>
  )
}

export default EditDCContact
