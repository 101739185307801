import { FC } from 'react'

const CategoryReturnRate: FC = () => {
  return (
    <div>
      <h1>Category Return Rate</h1>
    </div>
  )
}

export default CategoryReturnRate
