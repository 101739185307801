import { FC } from 'react'

const ReturnInsightsFilter: FC = () => {
  return (
    <div>
      <h1>Return Insights Filter</h1>
    </div>
  )
}

export default ReturnInsightsFilter
