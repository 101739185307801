import { FC } from 'react'

const PartnerLevelReturnRate: FC = () => {
  return (
    <div>
      <h1>Partner Level Return Rate</h1>
    </div>
  )
}

export default PartnerLevelReturnRate
