import { PartnerService } from 'types/PartnerServices'
import OverviewRoundel from '../../assets/pdfs/Target_Product_Ads_by_Roundel.pdf'
import Roundel from '../../assets/pdfs/Roundel.pdf'
import Salsify from '../../assets/pdfs/Salsify.pdf'
import Syndigo from '../../assets/pdfs/Syndigo.pdf'
import Syndication from '../../assets/pdfs/ReviewSyndication.pdf'
import ReviewSeeding from '../../assets/pdfs/ReviewSeeding.pdf'
import SocialContent from '../../assets/pdfs/SocialContent.pdf'
import StoryExpress from '../../assets/pdfs/StoryExpress.pdf'
import Imaging_V2 from '../../assets/pdfs/3D_Imaging_V2.pdf'
import { getRoundelUrl } from 'services/roundel'
import { getGargantuaUrl } from 'services/gargantua'

export const getServicesData = (isGargantuaSeller: boolean) => {
  let partnerServicesData: PartnerService[] = [
    {
      category: 'DIGITAL MARKETING',
      title: 'Target Product Ads',
      serviceProvider: 'Roundel™',
      description:
        'Give your product a boost with native, cost-per-click sponsored product ads on Target.com and the Target app by reaching high-intent bottom-funnel shoppers. Activate, manage, and report out on your media experiences with Roundel™ Media Studio!',
      image:
        'https://target.scene7.com/is/image/Target/GUEST_6e027469-e2af-45de-a25f-67f4aef5d5f3',
      aboutLinks: [
        { name: 'Manage Ads', link: getRoundelUrl() },
        { name: 'Overview', link: OverviewRoundel },
      ],
    },
    {
      category: 'DIGITAL MARKETING',
      title: 'Digital Media Campaigns',
      serviceProvider: 'Roundel™',
      description:
        'Drive awareness, consideration and conversion for your brands and products with world-class customer data, best-in-class media network, and actionable insights. Click to know more:',
      image:
        'https://target.scene7.com/is/image/Target/GUEST_3e2718b2-23f6-4d83-8673-66251355b492',
      aboutLinks: [{ name: 'Roundel', link: Roundel }],
    },
    {
      category: 'CONTENT SERVICES',
      title: 'Ratings & Reviews',
      serviceProvider: 'Bazaarvoice, PowerReviews and Yotpo',
      description:
        'Collect and share consumer generated reviews to give Target guests the confidence to choose your products. Click to know more:',
      image:
        'https://target.scene7.com/is/image/Target/GUEST_ce1ee4f8-cb96-4122-a38e-2f950994e1d5',
      aboutLinks: [
        { name: 'Review Syndication', link: Syndication },
        { name: 'Review Seeding', link: ReviewSeeding },
      ],
    },
    {
      category: 'CONTENT SERVICES',
      title: 'Enhanced Content',
      serviceProvider: 'Syndigo and Salsify',
      description:
        'Engage guests with buying guides, 360° views, and comparison charts published seamlessly to all of your Product Detail Pages on Target.com. Click to know more:',
      image:
        'https://target.scene7.com/is/image/Target/GUEST_7c04866c-10fd-41d1-92b0-fd15f74111d1',
      aboutLinks: [
        { name: 'Syndigo', link: Syndigo },
        { name: 'Salsify', link: Salsify, direct: true },
      ],
    },
    {
      category: 'CONTENT SERVICES',
      title: 'Social Content',
      serviceProvider: 'Bazaarvoice',
      description:
        'Source and display brand and user-generated social media content on product detail pages and select listing pages, to inspire guests. Click to know more:',
      image:
        'https://target.scene7.com/is/image/Target/GUEST_bbdec72b-7474-4add-b802-616a3d96354d',
      aboutLinks: [{ name: 'Bazaarvoice', link: SocialContent }],
    },
    {
      category: 'CONTENT SERVICES',
      title: 'Video',
      serviceProvider: 'StoryXpress',
      description:
        'Convert existing product images and descriptions into educational and high-performing video animations in just minutes. Click to know more:',
      image:
        'https://target.scene7.com/is/image/Target/GUEST_e11d41e3-fefa-4225-9649-eeb3c61e89db',
      aboutLinks: [{ name: 'StoryXpress', link: StoryExpress }],
    },
  ]

  if (isGargantuaSeller) {
    partnerServicesData.unshift({
      category: 'CONTENT SERVICES',
      title: '3D Imaging Service',
      serviceProvider: 'Target',
      description:
        "Offer guests an interactive experience with your products through 3D Imaging. Utilize our in-house service to enrich your PDP with features like 'View in 3D', 'See it in your space' and 'Plan a space with it.'",
      image:
        'https://target.scene7.com/is/image/Target/GUEST_c2316190-ba5e-44f1-bc4d-38a9fdf3c4bd',
      aboutLinks: [
        { name: 'Access 3D Service', link: getGargantuaUrl() },
        { name: 'Overview', link: Imaging_V2 },
      ],
    })
  }

  return partnerServicesData
}
