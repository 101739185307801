import { FC } from 'react'

const TopSKUType: FC = () => {
  return (
    <div>
      <h1>Top SKU Type</h1>
    </div>
  )
}

export default TopSKUType
