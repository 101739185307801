import { FC } from 'react'

const ReturnRate: FC = () => {
  return (
    <div>
      <h1>Return Rate</h1>
    </div>
  )
}

export default ReturnRate
