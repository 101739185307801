import { useEffect, useState, Suspense } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes, useLocation } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import isEmpty from 'lodash/fp/isEmpty'

import styled from '@emotion/styled'
import { Theme, useTheme } from '@mui/material/styles'

import useMediaQuery from '@mui/material/useMediaQuery'

import Dialog, { DialogEnum } from 'components/common/Dialog'
import { surveyConfig } from 'components/common/SurveyDialog/surveyConfig'
import Header from 'components/common/Header'
import Footer from 'components/common/Footer'
import SideNav from 'components/common/SideNav'
import FullScreenLoader from 'components/common/loader/FullScreenLoader'
import ErrorBoundary from 'components/common/ErrorBoundary'
import Notifications from 'components/common/Notifications'

import { SMALL_SCREEN_SIZE } from 'constants/layout'

import usePrevious from 'hooks/usePrevious'

import { isUserRoleProductAdmin } from 'services/authorization'
import checkIsIe from 'services/browserUtils'
import { isBetweenDates } from 'services/dateService'
import { trackCustomEvent } from 'services/fireflyInsights'
import {
  hasAlertManagementPage,
  hasBarcodeReleasePage,
  hasKafkaTopicsSearchPage,
  hasReturnDisputeCasesPage,
  hasItemProcessingPage,
  hasPromotions,
  hasHolidayClosuresPage,
  hasPhoneEditPage,
  hasReturnsResearchPage,
  hasUnlistItemsPageAccess,
  hasReturnDashboardAccess,
} from 'services/pageAccess'
import {
  getItem,
  LOCAL_STORAGE_SURVEY_COMPLETED_REPORTS,
} from 'services/storageHelper'
import { getSelectedPath, RoutePath } from 'services/NavigationHelper'

import { openDialog } from 'store/dialog/actionCreator'
import { fetchEnumerations } from 'store/enumeration/actionCreators'
import { setSmallScreenSize } from 'store/layout/actionCreators'
import {
  currentSeller,
  getMemberOf,
  isRoleExternalUserSelector,
  getDialogIsDialogOpen,
  getDialogComponentName,
  getDialogComponentProps,
  getDialogRenderDialog,
  getIsFullPageLoaderOpen,
  getHeaderTitle,
} from 'store/selectors'

import { FireflyEvent } from 'types/FireflyInsights'

import { getVanityRedirectConfig } from './vanityRedirectConfig'
import GenerateRedirect from './vanityRedirectConfig/GenerateRedirect'

import ReportsPage from 'components/ReportsPage'
import ItemVersionsPage from 'components/ItemVersionsPage'
import KafkaTopicsSearchPage from 'components/KafkaTopicsSearchPage'
import BarcodeReleasePage from 'components/BarcodeReleasePage'
import Home from 'components/Home'
import Orders from 'components/Orders'
import PromotionsPage from 'components/PromotionsPage'
import SeasonalEventsPage from 'components/SeasonalEventsPage'
import ProtectedBrandsPage from 'components/ProtectedBrandsPage'
import ReturnDisputeCasesPage from 'components/ReturnDisputeCasesPage'
import ReviewQueuePage from 'components/ReviewQueuePage'
import Sellers from 'components/Sellers'
import SellersPage from 'components/Sellers/SellersPage'
import SourcesPage from 'components/SourcesPage'
import StripeAccountSuccessPage from 'components/StripeAccountSuccessPage'
import UserProfile from 'components/UserProfilePage'
import InformActPage from 'components/InformActPage'
import HelpPage from 'components/HelpPage'
import ArticleDetailsPage from 'components/HelpPage/ArticleDetailsPage'
import BaseReferralFeesPage from 'components/BaseReferralFeesPage'
import ShippingMethodsPage from 'components/ShippingMethodsPage'
import ReturnShippingRatesPage from 'components/ReturnShippingRatesPage'
import ErrorCodesPage from 'components/ErrorCodesPage'
import ItemsProcessingPage from 'components/ItemsProcessingPage'
import DataMappingPage from 'components/DataMappingPage'
import GlobalQuotasPage from 'components/GlobalQuotasPage'
import InformActVerificationPage from 'components/InformActVerificationPage'
import UserPhoneEditPage from 'components/UserPhoneEditPage'
import TimelinePage from 'components/TimelinePage'
import AlertManagementPage from 'components/AlertManagementPage'
import ReturnedOrdersPage from 'components/ReturnedOrdersPage'
import HolidayClosuresPage from 'components/HolidayClosuresPage'
import ShippingMarkupPage from 'components/ShippingMarkupPage'
import ReturnsResearchPage from 'components/ReturnsResearchPage'

import { ReturnInsights } from '../../v2/pages/internal'

import { Layout as CanvasLayout, Grid } from '@enterprise-ui/canvas-ui-react'
import { flag, FlagName } from 'flag'
import UnlistItemsPage from 'components/UnlistItemsPage'
import PartnerServicesPage from 'components/PartnerServicesPage'

const isIe = checkIsIe(navigator.userAgent)

const StyledContainer = styled(Grid.Container)(({ theme }) => ({
  padding: theme.spacing(5),
  flexDirection: isIe ? 'unset' : 'column',
}))

const StyledLayoutBody = styled(CanvasLayout.Body, {
  shouldForwardProp: (prop) => prop !== 'showSideNav',
})<{ showSideNav?: boolean }>(({ theme }) => ({
  marginTop: theme.spacing(6),
  gridColumn: '2/span 4 !important',
  minHeight: 'calc(100vh - 280px)',
}))

export const Layout = () => {
  const reduxDispatch = useDispatch()
  const location = useLocation()
  const isFullPageLoaderOpen = useSelector(getIsFullPageLoaderOpen)
  const isExternalUser = useSelector(isRoleExternalUserSelector)
  const seller = useSelector(currentSeller)
  const memberOf = useSelector(getMemberOf)
  const headerTitle = useSelector(getHeaderTitle)
  const isDialogOpen = useSelector(getDialogIsDialogOpen)
  const renderDialog = useSelector(getDialogRenderDialog)
  const componentName = useSelector(getDialogComponentName)
  const componentProps = useSelector(getDialogComponentProps)

  const [showSideNav, setShowSideNav] = useState(true)
  const [surveyAvailable, setSurveyAvailable] = useState<boolean>()
  const [showSurvey, setShowSurvey] = useState(false)
  const theme = useTheme<Theme>()

  const vanityRedirectConfig = getVanityRedirectConfig({
    memberOf,
    sellerSource: seller?.source,
    sellerStatus: seller?.status,
    openDialog: undefined,
  })

  useEffect(() => {
    reduxDispatch(fetchEnumerations())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      isExternalUser &&
      isBetweenDates(new Date(), surveyConfig.startDate, surveyConfig.endDate)
    ) {
      setSurveyAvailable(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isProductAdmin = isUserRoleProductAdmin(memberOf)

  const currentLocation = getSelectedPath(location)
  const surveyCompleted = getItem(LOCAL_STORAGE_SURVEY_COMPLETED_REPORTS)
  const prevLocation = usePrevious(currentLocation)

  useEffect(() => {
    let timeout: NodeJS.Timeout

    if (!surveyCompleted && surveyAvailable) {
      if (currentLocation === surveyConfig.page) {
        timeout = setTimeout(() => {
          setShowSurvey(true)
        }, 10000)
      }
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [currentLocation, surveyCompleted, surveyAvailable])

  useEffect(() => {
    if (
      showSurvey &&
      prevLocation === surveyConfig.page &&
      currentLocation !== surveyConfig.page
    ) {
      reduxDispatch(openDialog({ dialogEnum: DialogEnum.SURVEY_DIALOG }))
      trackCustomEvent(
        FireflyEvent.SURVEY_FEEDBACK,
        'survey',
        'show',
        surveyConfig.featureName,
      )
      setShowSurvey(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocation, prevLocation, showSurvey])

  const pageTitle =
    typeof headerTitle === 'string'
      ? headerTitle
      : headerTitle.map((breadcrumb) => breadcrumb.text).join(' > ')

  const matchesMediaQuery = useMediaQuery(
    theme.breakpoints.down(SMALL_SCREEN_SIZE),
  )

  useEffect(() => {
    if (matchesMediaQuery) {
      reduxDispatch(setSmallScreenSize(true))
    }
    if (!matchesMediaQuery) {
      reduxDispatch(setSmallScreenSize(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchesMediaQuery])

  const handleMenuButtonClick = () => {
    if (showSideNav) {
      setShowSideNav(false)
    } else {
      setShowSideNav(true)
    }
  }

  const handleCloseSideNav = () => {
    setShowSideNav(false)
  }

  return (
    <ErrorBoundary page="Layout">
      <Helmet
        defaultTitle="Target Plus"
        titleTemplate="%s - Target Plus"
        title={pageTitle}
      />

      <SideNav
        seller={seller}
        memberOf={memberOf}
        isExternalUser={isExternalUser}
        selectedItem={currentLocation}
        handleClose={handleCloseSideNav}
        open={showSideNav}
        setShowSideNav={setShowSideNav}
      />

      <Header
        title={headerTitle}
        menuAction={handleMenuButtonClick}
        showSideNav={showSideNav}
      />

      <StyledLayoutBody
        role="main"
        showSideNav={showSideNav}
        className="hc-ma-none"
      >
        <StyledContainer container>
          <Grid.Item xs={12} style={{ alignItems: 'stretch' }}>
            {!isEmpty(memberOf) && (
              <Suspense fallback={<div></div>}>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route
                    path={RoutePath.STRIPE_ACCOUNT_SUCCESS}
                    element={<StripeAccountSuccessPage />}
                  />
                  {isExternalUser &&
                    vanityRedirectConfig.map((item) => (
                      <Route
                        key={item}
                        path={item}
                        element={
                          <GenerateRedirect path={item} location={location} />
                        }
                      />
                    ))}
                  <Route
                    path={`${RoutePath.ALL_ORDERS}/*`}
                    element={<Orders />}
                  />
                  <Route
                    path={RoutePath.ALL_RETURNS}
                    element={<ReturnedOrdersPage />}
                  />
                  {hasPromotions(memberOf) && (
                    <Route
                      path={RoutePath.PROMOTIONS}
                      element={<PromotionsPage />}
                    />
                  )}
                  <Route
                    path={RoutePath.CALENDAR}
                    element={<SeasonalEventsPage />}
                  />
                  <Route path={RoutePath.SELLERS} element={<SellersPage />} />
                  <Route path={RoutePath.SOURCES} element={<SourcesPage />} />
                  <Route
                    path={RoutePath.REVIEW_QUEUE}
                    element={<ReviewQueuePage showSideNav={showSideNav} />}
                  />
                  {hasReturnDisputeCasesPage(memberOf) && (
                    <Route
                      path={RoutePath.INTERNAL_RETURN_DISPUTE_CASES}
                      element={<ReturnDisputeCasesPage />}
                    />
                  )}
                  {hasReturnDashboardAccess() && (
                    <Route
                      path={RoutePath.RETURN_PERFORMANCE_INSIGHTS}
                      element={<ReturnInsights />}
                    />
                  )}
                  {hasItemProcessingPage(memberOf) && (
                    <Route
                      path={RoutePath.ITEMS_PROCESSING}
                      element={<ItemsProcessingPage />}
                    />
                  )}
                  <Route
                    path={RoutePath.SHIPPING_METHODS}
                    element={<ShippingMethodsPage />}
                  />
                  {flag(FlagName.BUY_SHIPPING_MARKUP) && (
                    <Route
                      path={RoutePath.SHIPPING_MARKUP}
                      element={<ShippingMarkupPage />}
                    />
                  )}
                  <Route
                    path={RoutePath.RETURN_SHIPPING_RATES}
                    element={<ReturnShippingRatesPage />}
                  />
                  <Route
                    path={RoutePath.BASE_REFERRAL_FEES}
                    element={<BaseReferralFeesPage />}
                  />
                  <Route
                    path={RoutePath.USER_PROFILE}
                    element={<UserProfile />}
                  />
                  <Route
                    path={RoutePath.INFORM_ACT}
                    element={<InformActPage />}
                  />
                  <Route
                    path={`/${RoutePath.HELP_RELATIVE}/*`}
                    element={<HelpPage />}
                  />
                  <Route
                    path={`/${RoutePath.KNOWLEDGE_ARTICLE_RELATIVE}/:articleId`}
                    element={<ArticleDetailsPage />}
                  />
                  <Route
                    path={RoutePath.PROTECTED_BRANDS}
                    element={<ProtectedBrandsPage />}
                  />
                  <Route
                    path={RoutePath.GLOBAL_QUOTAS}
                    element={<GlobalQuotasPage />}
                  />
                  <Route
                    path={RoutePath.INFORM_ACT_VERIFICATION}
                    element={<InformActVerificationPage />}
                  />
                  {hasHolidayClosuresPage(memberOf) && (
                    <Route
                      path={RoutePath.HOLIDAY_CLOSURES}
                      element={<HolidayClosuresPage />}
                    />
                  )}
                  <Route
                    path={RoutePath.PARTNER_SERVICES}
                    element={<PartnerServicesPage />}
                  />
                  <Route
                    path={RoutePath.INTERNAL_REPORTS}
                    element={<ReportsPage />}
                  />
                  <Route
                    path={RoutePath.ERROR_CODES}
                    element={<ErrorCodesPage />}
                  />
                  <Route
                    path={RoutePath.DATA_MAPPING}
                    element={<DataMappingPage />}
                  />
                  <Route
                    path={RoutePath.ITEM_VERSIONS}
                    element={<ItemVersionsPage />}
                  />
                  {hasKafkaTopicsSearchPage(memberOf) && (
                    <Route
                      path={RoutePath.SEARCH_TOPICS}
                      element={<KafkaTopicsSearchPage />}
                    />
                  )}
                  {hasBarcodeReleasePage(memberOf) && (
                    <Route
                      path={RoutePath.BARCODE_RELEASE}
                      element={<BarcodeReleasePage />}
                    />
                  )}
                  {hasUnlistItemsPageAccess(memberOf) && (
                    <Route
                      path={RoutePath.UNLIST_ITEMS}
                      element={<UnlistItemsPage />}
                    />
                  )}
                  {hasPhoneEditPage(memberOf) && (
                    <Route
                      path={RoutePath.PHONE_EDIT}
                      element={<UserPhoneEditPage />}
                    />
                  )}
                  {isProductAdmin && (
                    <Route
                      path={RoutePath.TIMELINE_UTIL}
                      element={<TimelinePage />}
                    />
                  )}
                  {hasAlertManagementPage(memberOf) && (
                    <Route
                      path={RoutePath.ALERT_MANAGEMENT}
                      element={<AlertManagementPage />}
                    />
                  )}
                  {hasReturnsResearchPage(memberOf) && (
                    <Route
                      path={RoutePath.RETURNS_RESEARCH}
                      element={<ReturnsResearchPage />}
                    />
                  )}
                  <Route path=":sellerId/*" element={<Sellers />} />
                </Routes>
              </Suspense>
            )}
          </Grid.Item>
        </StyledContainer>
      </StyledLayoutBody>
      <CanvasLayout.Footer>
        <Footer />
      </CanvasLayout.Footer>
      <Notifications />
      <FullScreenLoader isOpen={isFullPageLoaderOpen} />
      {renderDialog && (
        <Dialog
          isOpen={isDialogOpen}
          componentName={componentName}
          componentProps={componentProps}
        />
      )}
    </ErrorBoundary>
  )
}

export default Layout
