import apiConfig, { ENVIRONMENT_PRODUCTION } from 'config/apiConfig'
export const stageGargantuaSellers = [
  // Thunder Brewing
  '5d9b63230b782d009720a6cb',
  //Salsify
  '6137d7acade0fa0c8e2973ab',
]

export const prodGargantuaSellers = [
  //Test accounts
  // Chaco Chaco 27
  '5c6335cc89cbfe024bdeecee',
  // Target
  '5aafd3eef243400552a5d8b9',

  //List of actual partners
  //Costway
  '60091a2b0dde462386d7803b',
  //Fifth Sun
  '5e0a23704cb5ce009759b23a',
  //Nuloom
  '5dcc71c371dc7a008d3cc45a',
  //Safavieh
  '5d949496fcd4b70097dfad5e',
  //Spreetail
  '5b5b1d3cbc563e03bad44170',
  //Tangkula Inc
  '627370716bb4d457cc5a95d1',
  //Unique Bargains
  '5ff3f1d4193ba547e817a366',
  //Yaheetech
  '6298af4d12e0625a84a39c94',
  //Aosom
  '6041ad6855bf895b23576f3c',
  //Bare Home
  '5e20855ffad87400973fcc79',
  //Best Choice Products
  '5fb4e972fd35233bfd8806e9',
  //Blue Nile Mills
  '5f201e069702827e5ddae797',
  //First Choice Home
  '5da9cda34d98ab0098af4831',
  //iEnjoy Home,
  '61dd12d55d89c53cffa3e7b3',
  //Lamps Plus
  '5dcc709d5a66c3009793db4c',
  //Levtex Home
  '5f9ac873fd35233bfd4ccdd8',
  //ModernLuxe
  '628dd96ade246c4a33f1ec3a',
  //Nourison
  '5c5d9b9b802fef024ac5b6b5',
  //POP Maison
  '6270d047b4a2301a0abd86a0',
  //Jonathan Y & Happimess
  '5ff2ac6827f74d40e7ddd5bb',
  //Diddly Deals
  '60c7e9317f2aab7ac194b76c',
  //RGA Ben
  '62e150ef64c5d310130c2b19',
  //Toynk
  '5b3290e63320f503ba531d0f',
  //Infinite Commerce
  '5d52bb844b51860097ce5577',
  //MyOfficeInnovations
  '5ebef05064a9cf12e8cd4373',
  //Lincoln's Department Store
  '60883283d36748089cf0c8f5',
  //California Design Den
  '5dadaa4e5175820097ccdf79',
  //Puredown
  '5f4e1dabeff4757973efe69b',
  //Entrotek
  '5eebf4605fd0c76e7f9c87a5',
  //InterDesign
  '5d23399869178e008d3f7018',
  //Zwilling J.A. Henckels
  '5c54a8b99d11d0024ad29d8a',
  //Great Bay Home
  '5da0dd27f00dae0097eb47af',
  //Christmas Central
  '5baaa2c6950bba03ba3777eb',
  //CGK Unlimited
  '5f3f7701b90af15d65abe0e6',
  //CPO Outlets
  '5e66e95c8c7cf82c918741b5',
  //eLuxury
  '5d13adad2d2005008edd3fe5',
  //Halloween Express
  '6290a77ce6893d64ccd09ff1',
  //LIVNCO
  '6560a3f313c97d3adbed2624',

  //Phase 1 Partners
  //IRIS USA, Inc
  '61850814a453a24921ad1a54',
  //SONGMICS HOME
  '6516ddc561c4c63d4dc309b4',
  //GoodGram
  '5e2ef5895c20b90097d87b99',
  //Mix Wholesale
  '6284433bdb90105223174287',
  //DOWN-LITE INTERNATIONAL
  '5db858d5aadced00970ce739',
  //Home it USA
  '603731e08855234cec076f33',
  //RealRooms
  '5e737d102aa261115315d2d3',
  //Sunnydaze Decor
  '5b05cd217e674d03ba1e36f2',
  //Lambs & Ivy
  '605259509b32e43e99388d73',
  //American Soft Linen
  '648bf74d7df4423288ae6ac8',
  //Sorbus
  '63cfebf99f4ac1702ce60d49',
  //Saro Lifestyle
  '5f6835655e287328a67e274d',
  //Everyday Goods
  '64c0096f8454be60343679a3',
  //Karat Home
  '6108e448e88f860bbae9bcd5',
  //Sweet Home
  '6205d7b8c8379b6c040526c1',
  //Big Dot of Happiness
  '5fbca32a66a67d5c57b23532',
  //Tribesigns
  '6516ddc561c4c63d4dc309b0',
  //HalloweenCostumes.com
  '6287865222ae7325efb5e20d',
  //Meri Meri
  '5d4451135bcf15008da93781',
  //Sweet Jojo Designs
  '648bf46da391bf735e73b221',
  //Unique Loom
  '62be854929865702484497ea',
  //Vickerman
  '5e713030936332743798b619',
  //Scout Limited
  '5e57f1af6a0ab50097a1da1f',
  //Elrene Home Fashions
  '5c3cadd3a84b2e024a4da1c4',
  //Libbey
  '61b6e2a91b1bc138cc308928',
  //Caravan Group
  '6488131cf14bc3105e1ac7ce',
  //NUTRICHEF
  '63a34f4c3804b0212e74f941',
  //Collections Etc
  '62c56adccf6d546e97c94111',
  //House of Dreams
  '64255f8b6f638a28a2a366b9',
  //Synergy
  '5ffd86a5cf86f13044429205',
  //Guidecraft
  '642d1e8cdad32c096261d7f0',
  //Luxe Weavers
  '6241620459fbdb039ceef0c4',
  //Cheer Collection
  '648a0fcdd5a6f121695331d6',
  //Mr. Christmas
  '5ed81c8fa804b213314e2f83',
  //Andmakers
  '6372850232bb5c5ac7ee361b',
  //True Brands
  '618514f6e30c011c0f8af160',
  //Rubie's DTC
  '5ebda9220690b52260e1a9de',
  //Modern Threads
  '5ecfeddade90b4456be746c6',
  //Saturday Park
  '5d819c9aca9fde00971d5ff1',
  //Decor Avenue
  '5fe2e667193ba547e8ef48b7',
  //LuxenHome
  '638767c6d83e8e4de9b0470b',
  //National Tree Company
  '60a4aadd7600ce190e921d4d',
  //Sinomax USA
  '62a081ffe6893d64ccecd0f7',
  //Aoodor
  '63ef3fcc94538b7190470f8b',
  //ECR3Kids
  '5cd96259aa09fb0097c75e66',
  //Kate and Laurel
  '6406fed7e6ffa22331a49ab1',
  //Danby
  '5ee0f02f0b2a995f04331239',
  //Verdi
  '61918a3f4cc80331167d520c',
  //BirdRock Home
  '6213b60393eff07b7f33cb50',
  //Agave Market
  '5fe07fe3dc7f3b4ebfa9f688',
]

const stageGargantuaUrl = 'https://extgargantua.perf.target.com'
const prodGargantuaUrl = 'https://extgargantua.target.com'

export const isGargantuaSeller = (sellerId: string) => {
  const gargantuaSeller =
    apiConfig.environment !== ENVIRONMENT_PRODUCTION
      ? stageGargantuaSellers
      : prodGargantuaSellers

  return gargantuaSeller.includes(sellerId)
}

export const getGargantuaUrl = () => {
  const baseUrl = window.location.origin

  return apiConfig.environment !== ENVIRONMENT_PRODUCTION
    ? `${stageGargantuaUrl}?redirectUrl=${baseUrl}`
    : `${prodGargantuaUrl}?redirectUrl=${baseUrl}`
}
