import { FC } from 'react'
import { Grid } from '@enterprise-ui/canvas-ui-react'

import Filter from './Filter'
import CategoryReturnRate from './CategoryReturnRate'
import PartnerLevelReturnRate from './PartnerLevelReturnRate'
import ReturnRate from './ReturnRate'
import TopItemType from './TopItemType'
import TopSKUType from './TopSKUType'

const ReturnInsights: FC = () => {
  return (
    <Grid.Container>
      <Grid.Item xs={12}>
        <Filter />
      </Grid.Item>
      <Grid.Item xs={6}>
        <ReturnRate />
      </Grid.Item>
      <Grid.Item xs={6}>
        <PartnerLevelReturnRate />
      </Grid.Item>
      <Grid.Item xs={6}>
        <CategoryReturnRate />
      </Grid.Item>
      <Grid.Item xs={6}>
        <TopItemType />
      </Grid.Item>
      <Grid.Item xs={12}>
        <TopSKUType />
      </Grid.Item>
    </Grid.Container>
  )
}

export default ReturnInsights
