import { SmsAddress } from './Address'
import SellerUser, { PhoneNumber } from './SellerUser'

export interface Carrier {
  id: number
  shipping_account?: string
}

export interface DaysOfOperation {
  working_hours: WorkingHours[]
  days_closed: string[]
}

export interface ShipNode {
  id?: string
  vmm_id?: number
  name: string
  carriers?: Carrier[]
  service_levels?: ShippingService[]
  building_lead_time_id?: number
  max_orders_per_day?: Nullable<number>
  created?: string
  last_modified?: string
  last_modified_by?: string
}

export interface ShippingService {
  id: number
  cut_off_time?: string
  generic_time_in_transit?: number
  building_lead_time_id?: number
  days_to_add?: number
  max_days_to_add?: number
  building_lead_time_id_override?: number
  last_adjusted?: string
}

export enum ShippingServiceCode {
  STANDARD = 6222,
  EXPRESS = 6220,
}

export interface SmsDistributionCenter {
  id: string
  vmm_id: number
  name?: string
  ecom_id: number
  address: SmsAddress
  days_of_operation: DaysOfOperation
  seller_user_id?: string
  ship_nodes: ShipNode[]
  two_day_state_codes?: string[]
  timezone?: string
  created?: string
  last_modified?: string
  last_modified_by?: string
  display_name?: string
}

export enum ReturnPolicyStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface SmsReturnPolicy {
  id?: string
  vmm_id?: string
  financial_disposition_id?: number
  physical_disposition_id?: number
  in_care_of?: string
  return_seller_user_id?: string
  returnContact?: SellerUser
  return_address?: Partial<SmsAddress>
  created?: string
  last_modified?: string
  last_modified_by?: string
  special_return_instructions?: string
  status?: ReturnPolicyStatus
}

export interface InternalTaxSetting {
  setup_complete: boolean
}

export enum ReviewIndicators {
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  DESCRIPTION = 'DESCRIPTION',
}

export interface ReviewIndicator {
  name: ReviewIndicators
  reviewed: boolean
  last_modified?: string
  last_modified_by?: string
}

export enum CompanyType {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export interface SmsSeller {
  display_name?: string
  legal_business_name: string
  status: SellerStatus
  business_structure?: string
  created?: string
  created_by?: string
  description?: string
  id: string
  is_private?: boolean
  last_modified?: string
  last_modified_by?: string
  header_image?: string
  logo_image?: string
  primary_address?: SmsAddress
  primary_seller_user_id?: string
  privacy_policy?: string
  return_policies?: SmsReturnPolicy[]
  source?: string
  stripe_account_id?: string
  tax_id?: string
  vmm_id?: string
  distribution_centers?: SmsDistributionCenter[]
  max_product_quota: number
  internal_tax_setting: InternalTaxSetting
  review_indicators: ReviewIndicator[]
  in_other_marketplaces?: boolean
  is_importer_of_consumer_product?: boolean
  is_manufacturer_of_consumer_product?: boolean
  is_reseller_of_consumer_product?: boolean
  guest_services_email?: string
  guest_services_phone_number?: PhoneNumber
  has_guest_services_phone_number?: boolean
  has_beneficial_ownership_information?: boolean
}

export interface WorkingHours {
  day: string
  open_time: string
  close_time: string
}

export interface SellerMatch {
  sellerId: string
}

export enum SellerStatus {
  INITIATED = 'INITIATED',
  PARTNER_SETUP = 'PARTNER_SETUP',
  KICK_OFF = 'KICK_OFF',
  DATA_INTEGRATION = 'DATA_INTEGRATION',
  RAMP_UP = 'RAMP_UP',
  ONBOARDING_COMPLETE = 'ONBOARDING_COMPLETE',
  ESTABLISHED = 'ESTABLISHED',
  SUSPENDED = 'SUSPENDED',
  DEFUNCT = 'DEFUNCT',
}

export enum Source {
  ACENDA = 'ACENDA',
  BIG_COMMERCE = 'BIG_COMMERCE',
  CHANNEL_ADVISOR = 'CHANNEL_ADVISOR',
  COMMERCEHUB = 'COMMERCEHUB',
  DIRECT_FROM_SELLER = 'DIRECT_FROM_SELLER',
  ECOMDASH = 'ECOMDASH',
  GEEKSELLER = 'GEEKSELLER',
  LISTING_MIRROR = 'LISTING_MIRROR',
  SELLBRITE = 'SELLBRITE',
  SELLER_ACTIVE = 'SELLER_ACTIVE',
  SOLID_COMMERCE = 'SOLID_COMMERCE',
  ZENTAIL = 'ZENTAIL',
}

export interface Code {
  code_id: number
  code_name: string
  code_description?: string
}

export interface VendorCategory {
  category_id: string
  category_name: string
  codes: Code[]
}

export interface SellerSearchParams {
  q?: string
  status?: SellerStatus | SellerStatus[]
  vmm_id?: string
  legal_business_name?: string
  source?: string
  fields?: string[]
  ship_node_id?: string
}

export interface SellerStatusHistory {
  id: string
  seller_id: string
  approval_status: SellerStatus
  created: string
  created_by: string
}

export type SellerSourceStatus = 'ACTIVE' | 'INACTIVE'
export interface SellerSource {
  id: string
  description: string
  status: SellerSourceStatus
  multi_ship_node: boolean
  created: string
  created_by: string
  last_modified: string
  last_modified_by: string
}
